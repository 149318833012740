<template>
  <div class="inner-dashboard">
    <PropertyDashboardSidebarNav :propertyId="propertyId" />
    <div class="container h-100 pt-4">
      <div
        class="property-dashboard-content-heading row justify-content-between"
      >
        <div class="d-flex justify-content-start align-items-center">
          <div class="current-property">Marketing Analytics</div>
          <select
            class="form-select ms-4"
            v-model="projectId"
            id="listMAProjects"
          >
            <option
              v-for="(item, key) in projectList"
              :key="key"
              :value="key + 1"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </div>

      <div class="section summary-container">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div><label class="form-label mb-0">Views</label></div>
          <div class="date-range-picker position-relative">
            <VueDatePicker
              v-model="dateRange"
              range
              multi-calendars
              :min-date="rangeStart"
              :max-date="yesterday"
              v-if="!isLoading"
              :enable-time-picker="false"
            >
              <template #left-sidebar>
                <div v-for="(val, key) in rangeTypes" :key="key">
                  <button
                    type="button"
                    class="btn"
                    :class="{ active: this.rangeType == key }"
                    @click="updateRange(key)"
                  >
                    {{ val }}
                  </button>
                </div>
              </template>
            </VueDatePicker>
          </div>
        </div>

        <div class="position-relative">
          <loading
            v-model:active="isLoading"
            :is-full-page="false"
            loader="dots"
            :opacity="1"
          />
          <div class="row row-cols-3 row-cols-xl-6">
            <div
              class="col mt-2 mt-xl-0"
              v-for="(item, key) in kpis"
              :key="key"
            >
              <div class="kpi-container">
                <p class="kpi-header mb-0 custom-field">
                  {{ key }}
                </p>
                <p class="kpi-value mb-0">{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section position-relative">
        <loading
          v-model:active="isContactsLoading"
          :is-full-page="false"
          loader="dots"
          :opacity="1"
        />
        <div>
          <label class="form-label">Contacts Identified</label>
        </div>
        <div class="table-container">
          <DatatableComponent
            :data="contacts_identified"
            :columns="columns1"
            :options="{
              paging: false,
              searching: false,
              info: false,
              responsive: true,
              stripeClasss: [],
              ordering: true,
              fixedHeader: true,
              scrollX: true,
            }"
            :keys="keys1"
          />
        </div>
      </div>

      <div class="section position-relative">
        <loading
          v-model:active="isCompaniesLoading"
          :is-full-page="false"
          loader="dots"
          :opacity="1"
        />
        <div>
          <label class="form-label">Companies Identified</label>
        </div>
        <div class="table-container">
          <DatatableComponent
            :data="companies_identified"
            :columns="columns2"
            :options="{
              paging: false,
              searching: false,
              info: false,
              responsive: true,
              stripeClasss: [],
              ordering: true,
              fixedHeader: true,
              scrollX: true,
            }"
            :keys="keys2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.kpi-container
  border: 1px solid $LightGreyBorder
  border-radius: 20px
  width: 100%
  min-height: 101px
  height: 100%
  .kpi-header
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: #06152B
    padding: 23px 15px 12px
    white-space: nowrap
    +laptop
      font-size: 15px
    +mobile
      font-size: 14px
      padding: 14px 14px 12px
  .kpi-value
    font-weight: 600
    font-size: 16px
    line-height: 23px
    padding: 0 15px 22px
    color: $Main
    +laptop
      font-size: 17px
    +mobile
      font-size: 16px
      padding: 0 14px 14px
      span
        font-weight: 600
      .month-avg
        margin-right: 8px
      .contract-value
        color: $Blue
        font-size: 12px
        font-weight: 400
        line-height: 15px
        display: inline-block
  +mobile
    margin-bottom: 10px
    height: 90%

#listMAProjects
  width: 250px
  margin-bottom: 0
</style>

<script>
import { ref } from "vue";
import DatatableComponent from "@/components/DatatableComponent.vue";
import moment from "moment";
import PropertyDashboardSidebarNav from "@/components/PropertyDashboardSidebarNav.vue";
import api from "@/services/BackendConnection";

export default {
  name: "CampaignReportView",
  setup(props) {
    var yesterday = moment().subtract(1, "days").toDate();
    var start = moment("2024-01-01").toDate();
    var contacts_data = [
      // {
      //   name: "Annalisa Hepburn",
      //   jobTitle: "Senior Client Service Specialist",
      //   logo: "Annalisa_Hepburn.jpeg",
      //   company: "OnPace Partners",
      //   email: "annalisa@onpacepartners.com",
      //   LinkedIn: "http://www.linkedin.com/in/annalisa-hepburn-9578aa46",
      //   location: "Atlanta, GA",
      //   website: "http://www.onpacepartners.com",
      //   pageViews: 1744,
      //   viewed: null,
      //   lastSeen: moment("2024-09-08").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Enoch Hayase",
      //   jobTitle: "Managing Director",
      //   logo: "Enoch_Hayase.jpeg",
      //   company: "Ares Management Corporation",
      //   email: null,
      //   LinkedIn: "http://www.linkedin.com/in/enoch-hayase-1229154",
      //   location: "New York, NY",
      //   website: "http://www.aresmgmt.com",
      //   pageViews: 32,
      //   viewed: null,
      //   lastSeen: moment("2024-09-15").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Walker Collier",
      //   jobTitle: "Managing Partner",
      //   logo: "Walker_Collier.jpeg",
      //   company: "Trinity Capital Advisors",
      //   email: "cwc@trinitycapitaladvisors.com",
      //   LinkedIn: "http://www.linkedin.com/in/walker-collier",
      //   location: " Broomfield, CO",
      //   website: "http://www.trinitycapitaladvisors.com",
      //   pageViews: 222,
      //   viewed: null,
      //   lastSeen: moment("2024-09-07").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Cesar Ortiz",
      //   jobTitle: "Gerente de ventas SME Michoacán",
      //   logo: "Cesar_Ortiz.jpeg",
      //   company: "AT&T, México",
      //   email: null,
      //   LinkedIn: "http://www.linkedin.com/in/cesar-eduardo-ortiz-e-58a849127",
      //   location: "Durham, NC",
      //   website: "http://www.att.com.mx",
      //   pageViews: 222,
      //   viewed: null,
      //   lastSeen: moment("2024-09-08").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Joseph Aronoff",
      //   jobTitle: "Authentics Event Staff",
      //   logo: "Joseph_Aronoff.jpeg",
      //   company: "Detroit Tigers",
      //   email: "jaronoff38@gmail.com",
      //   LinkedIn: "http://www.linkedin.com/in/joseph-aronoff-4178291a1",
      //   location: "Ashburn, VA",
      //   website: "http://www.tigers.com",
      //   pageViews: 573,
      //   viewed: null,
      //   lastSeen: moment("2024-09-12").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Scott Nicholson",
      //   jobTitle: "Managing Director",
      //   logo: "Scott_Nicholson.jpeg",
      //   company: "InSite Real Estate",
      //   email: "snicholson@insiterealestate.com",
      //   LinkedIn: "http://www.linkedin.com/in/scott-nicholson-89814413",
      //   location: "Hinsdale, IL",
      //   website: "http://www.insiterealestate.com",
      //   pageViews: 864,
      //   viewed: null,
      //   lastSeen: moment("2024-09-14").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Trey Adams",
      //   jobTitle: "Managing Partner at Atlas Stark Holdings",
      //   logo: "Trey_Adams.jpeg",
      //   company: "Atlas Stark",
      //   email: null,
      //   LinkedIn: "https://linkedin.com/in/trey-adams-bb572913",
      //   location: "Fayetteville, NC",
      //   website: "https://atlasstark.com",
      //   pageViews: 21,
      //   viewed: null,
      //   lastSeen: moment("2024-09-11").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Leslie Holmes",
      //   jobTitle: "Client Services Team Lead",
      //   logo: "Leslie_Holmes.jpeg",
      //   company: "CBRE | Raleigh",
      //   email: "leslie.holmes@cbre-raleigh.com",
      //   LinkedIn: "http://www.linkedin.com/in/leslieanneholmes",
      //   location: "Broomfield, CO",
      //   website: "http://www.cbre.com",
      //   pageViews: 467,
      //   viewed: null,
      //   lastSeen: moment("2024-09-05").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Ranjana Sterling",
      //   jobTitle: "Senior Marketing Strategist",
      //   logo: "Ranjana_Sterling.jpeg",
      //   company: "CBRE",
      //   email: "ranjana.sterling@cbre.com",
      //   LinkedIn: "http://www.linkedin.com/in/ranjana-sterling-a566594b",
      //   location: "Kirkland, WA",
      //   website: "http://www.cbre.com",
      //   pageViews: 432,
      //   viewed: null,
      //   lastSeen: moment("2024-09-06").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Brandon Nickoles",
      //   jobTitle: "Assistant Development Director",
      //   logo: "Brandon_Nickoles.jpeg",
      //   company: "Wexford Science & Technology, LLC",
      //   email: "brandonnickoles@gmail.com",
      //   LinkedIn: "http://www.linkedin.com/in/brandon-nickoles-947054105",
      //   location: "Broomfield, CO",
      //   website: "http://www.wexfordscitech.com",
      //   pageViews: 43,
      //   viewed: null,
      //   lastSeen: moment("2024-09-06").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Ben Brenner",
      //   jobTitle: "VP of Sales",
      //   logo: "Ben_Brenner.jpeg",
      //   company: "Keen Decision Systems",
      //   email: "ben.brenner@keends.com",
      //   LinkedIn: "http://www.linkedin.com/in/ben-brenner-9b47698a",
      //   location: "New York, NY",
      //   website: "http://www.keends.com",
      //   pageViews: 83,
      //   viewed: null,
      //   lastSeen: moment("2024-09-05").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Ellen Lerner",
      //   jobTitle: "Retired from full-time work | Senior Account Manager",
      //   logo: "Ellen_Lerner.jpeg",
      //   company: "Epam Systems",
      //   email: null,
      //   LinkedIn: "https://linkedin.com/in/ellenlerner",
      //   location: "Albany, NY",
      //   website: "https://epam.com",
      //   pageViews: 95,
      //   viewed: null,
      //   lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Lauren Berry",
      //   jobTitle: "Senior Manager",
      //   logo: "Lauren_Berry.jpeg",
      //   company: "Maxis Advisors",
      //   email: "l.berry@maxisadvisors.com",
      //   LinkedIn: "http://www.linkedin.com/in/laurenjohnsonberry",
      //   location: "Charlotte, NC",
      //   website: "http://www.maxisadvisors.com",
      //   pageViews: 532,
      //   viewed: null,
      //   lastSeen: moment("2024-09-02").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Mary Moshofsky",
      //   jobTitle: "Office Advisory & Transaction Services",
      //   logo: "Mary_Moshofsky.jpeg",
      //   company: "CBRE",
      //   email: "marylynn.moshofsky@cbre.com",
      //   LinkedIn: "http://www.linkedin.com/in/mary-lynn-moshofsky-3582855a",
      //   location: "Dallas, TX",
      //   website: "http://www.cbre.com",
      //   pageViews: 1320,
      //   viewed: null,
      //   lastSeen: moment("2024-09-01").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Zach Vall-SpinosaZach Vall-Spinosa",
      //   jobTitle: "Executive Vice President / Partner",
      //   logo: "Zach_Vall_Spinosa.jpeg",
      //   company: "Kidder Mathews",
      //   email: "zach.vallspinosa@kidder.com",
      //   LinkedIn: "http://www.linkedin.com/in/zach-vall-spinosa-bb694532",
      //   location: "Kirkland, WA",
      //   website: "http://www.kidder.com",
      //   pageViews: 249,
      //   viewed: null,
      //   lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      // },
      {
        name: "Shannon Loew",
        jobTitle: "Vice President, Real Estate & Facilities",
        logo: "shannon_loew.jpeg",
        company: "Amazon",
        email: "shloew@amazon.com",
        LinkedIn: "https://www.linkedin.com/in/shannon-loew-0614362/",
        location: "Seattle, WA",
        website: "https://www.amazon.com/",
        pageViews: 21,
        viewed: null,
        lastSeen: moment("2024-09-12").format("MMM D, YYYY"),
      },
      {
        name: "Craig Irwin",
        jobTitle: "Director of Real Estate",
        logo: "craig_irwin.jpeg",
        company: "Deloitte",
        email: "cirwin@deloitte.ca",
        LinkedIn: "https://www.linkedin.com/in/craig-irwin-44a7a564/",
        location: "Toronto, ON",
        website: "https://www2.deloitte.com/ca/en/pages/about-deloitte/articles/home.html",
        pageViews: 34,
        viewed: null,
        lastSeen: moment("2024-09-08").format("MMM D, YYYY"),
      },
      {
        name: "Jeff Hardman",
        jobTitle: "Vice President, Real Estate",
        logo: null,
        company: "Home Depot",
        email: "jeffhardman@homedepot.com",
        LinkedIn: "https://www.linkedin.com/in/jeff-hardman-674b1310/",
        location: "Atlanta, GA",
        website: "https://www.homedepot.com/",
        pageViews: 12,
        viewed: null,
        lastSeen: moment("2024-09-06").format("MMM D, YYYY"),
      },
      {
        name: "Trey Conway",
        jobTitle: "Director of Real Estate",
        logo: "trey_conway.jpeg",
        company: "Home Depot",
        email: "treyconway@homedepot.com",
        LinkedIn: "https://www.linkedin.com/in/trey-conway-2391b11a/",
        location: "Atlanta, GA",
        website: "https://www.homedepot.com/",
        pageViews: 52,
        viewed: null,
        lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      },
      {
        name: "Randall Fears",
        jobTitle: "Real Estate",
        logo: "randall_fears.jpeg",
        company: "Tesla",
        email: "annalisa@onpacepartners.com",
        LinkedIn: "http://www.linkedin.com/in/annalisa-hepburn-9578aa46",
        location: "Austin, TX",
        website: "https://www.tesla.com/",
        pageViews: 12,
        viewed: null,
        lastSeen: moment("2024-09-03").format("MMM D, YYYY"),
      },
    ];
    var companies_data = [
      {
        company: "Deloitte",
        location: "Toronto, ON",
        logo: "deloitte_logo.jpeg",
        website: "http://www.deloitte.com/",
        industry: "Business Consulting and Services",
        size: "10,001+ employees",
        pageViews: 2930,
        viewed: null,
        lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      },
      {
        company: "GMC",
        location: "Detroit, MI",
        logo: "GMC.jpeg",
        website: "https://www.gmc.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1743,
        viewed: null,
        lastSeen: moment("2024-09-01").format("MMM D, YYYY"),
      },
      {
        company: "Ford",
        location: "Dearborn, MI",
        logo: "ford_motor_company_logo.jpeg",
        website: "https://corporate.ford.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1055,
        viewed: null,
        lastSeen: moment("2024-09-13").format("MMM D, YYYY"),
      },
      {
        company: "Tesla",
        location: "Austin, TX",
        logo: "tesla_motors_logo.jpeg",
        website: "https://www.tesla.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1937,
        viewed: null,
        lastSeen: moment("2024-09-11").format("MMM D, YYYY"),
      },
      {
        company: "Toyota",
        location: "Toyota, Aichi",
        logo: "toyota_logo.jpeg",
        website: "http://www.toyota-global.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1203,
        viewed: null,
        lastSeen: moment("2024-09-11").format("MMM D, YYYY"),
      },
      {
        company: "Amazon",
        location: "Seattle, WA",
        logo: "amazon_logo.jpeg",
        website: "https://www.aboutamazon.com/",
        industry: "Software Development",
        size: "10,001+ employees",
        pageViews: 2069,
        viewed: null,
        lastSeen: moment("2024-09-17").format("MMM D, YYYY"),
      },
      {
        company: "Home Depot",
        location: "Atlanta, GA",
        logo: "the_home_depot_logo.jpeg",
        website: "http://www.careers.homedepot.com",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 1026,
        viewed: null,
        lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      },
      {
        company: "Target",
        location: "Minneapolis, MN",
        logo: "Target.png",
        website: "http://www.target.com/",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 2418,
        viewed: null,
        lastSeen: moment("2024-09-16").format("MMM D, YYYY"),
      },
      {
        company: "Purolator",
        location: "Mississauga, ON",
        logo: "purolatorinc_logo.jpeg",
        website: "https://www.purolator.com",
        industry: "Freight and Package Transportation",
        size: "10,001+ employees",
        pageViews: 1873,
        viewed: null,
        lastSeen: moment("2024-09-16").format("MMM D, YYYY"),
      },
      {
        company: "UPS",
        location: "Atlanta, GA",
        logo: "ups_logo.jpeg",
        website: "https://about.ups.com/",
        industry: "Truck Transportation",
        size: "10,001+ employees",
        pageViews: 1638,
        viewed: null,
        lastSeen: moment("2024-09-12").format("MMM D, YYYY"),
      },
      {
        company: "Fedex",
        location: "Atlanta, GA",
        logo: "fedex_logo.jpeg",
        website: "http://fedex.com",
        industry: "Freight and Package Transportation",
        size: "10,001+ employees",
        pageViews: 1728,
        viewed: null,
        lastSeen: moment("2024-09-05").format("MMM D, YYYY"),
      },
      {
        company: "Rona",
        location: "Boucherville, QC",
        logo: "rona_logo.jpeg",
        website: "https://www.rona.ca/en",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 2910,
        viewed: null,
        lastSeen: moment("2024-09-17").format("MMM D, YYYY"),
      },
      {
        company: "Golf Town",
        location: "Vaughan, ON",
        logo: "golf_town_logo.jpeg",
        website: "http://www.joingolftown.com",
        industry: "Retail",
        size: "1,001-5,000 employees",
        pageViews: 1209,
        viewed: null,
        lastSeen: moment("2024-09-09").format("MMM D, YYYY"),
      },
      {
        company: "Cineplex",
        location: "Toronto, ON",
        logo: "cineplex_entertainment_logo.jpeg",
        website: "http://www.cineplex.com",
        industry: "Entertainment Providers",
        size: "10,001+ employees",
        pageViews: 1820,
        viewed: null,
        lastSeen: moment("2024-09-10").format("MMM D, YYYY"),
      },
      {
        company: "Winners",
        location: "Mississauga, ON",
        logo: "winners.png",
        website: "https://www.winners.ca/",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 2121,
        viewed: null,
        lastSeen: moment("2024-09-14").format("MMM D, YYYY"),
      },
    ];
    var kpis = [
      {
        Website: "16,000",
        "Contact Us": 12,
        Brochure: 333,
        Video: 132,
        "Virtual Tour": "N/A",
        "Space Calulator": "N/A",
      },
      {
        Website: "4,523",
        "Contact Us": 4,
        Brochure: 98,
        Video: 400,
        "Virtual Tour": "N/A",
        "Space Calulator": "N/A",
      },
      {
        Website: "6,748",
        "Contact Us": 5,
        Brochure: 152,
        Video: 171,
        "Virtual Tour": "N/A",
        "Space Calulator": "N/A",
      },
      {
        Website: "3,729",
        "Contact Us": 3,
        Brochure: 83,
        Video: 97,
        "Virtual Tour": "N/A",
        "Space Calulator": "N/A",
      },
    ];

    return {
      yesterday: yesterday,
      dateRange: ref([
        moment()
          .startOf("month")
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        moment()
          .subtract(1, "days")
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      ]),
      rangeType: ref("thisMonth"),
      rangeTypes: ref({
        yesterday: "Yesterday",
        last7: "Last 7 Days",
        last30: "Last 30 Days",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        all: "All",
      }),
      isLoading: ref(false),
      rangeStart: start,
      projectList: [
        "All pages",
        "Buda Commerce",
        "1280 Broadway",
        "305 Hermintston",
      ],
      projectId: ref(1),
      kpisArr: kpis,
      kpis: ref(kpis[0]),
      keys1: [
        "Name",
        "Name-Hidden",
        "Job Title",
        "Company",
        "Location",
        "Website",
        "Email",
        "LinkedIn",
        // "Page Views",
        // "Page Views-Hidden",
        // "Viewed",
        // "Last Seen",
      ],
      keys2: [
        "Company",
        "Company-Hidden",
        "Company Location",
        "Website",
        "Industry",
        "Company Size",
        // "Page Views",
        // "Page Views-Hidden",
        // "Viewed",
        // "Last Seen",
      ],
      columns1: [
        {
          data: "name",
          defaultContent: "",
          orderData: [1],
          targets: [0],
          className: "align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              // var url = row.logo ? new URL(`../assets/${row.logo}`, import.meta.url).href : new URL('../assets/na.jpeg', import.meta.url).href;
              // var style =
              //   "height:25px; width:25px; margin: 0 19px 0 0; border-radius: 50%;";
              return (
                '<span class="d-flex align-items-center"><span style="display:inline-block">' +
                data +
                "</span></span>"
              );
            }
          },
        },
        {
          data: "name",
          visible: false,
          searchable: false,
          targets: [1],
        },
        {
          data: "jobTitle",
          className: "text-center align-middle",
        },
        {
          data: "company",
          className: "text-center align-middle",
        },
        {
          data: "location",
          className: "text-center align-middle",
        },
        {
          data: "website",
          className: "align-middle",
          orderable: false,
          render: function (data, type, row, meta) {
            var url = new URL(`../assets/world-wide-web.png`, import.meta.url)
                .href;
            return (
                '<a href="' +
                data +
                '" target="_blank" style="background-color:rgba(10, 102, 194, 0.1); padding: 9px; border-radius: 8px; display: inline-block;"><span class="d-flex align-items-center"> <img src="' +
                url +
                '" style="height:20px; width:20px;" /></span></a>'
            );
          },
        },
        {
          data: "email",
          className: "text-center align-middle",
          defaultContent: "Unknown",
        },
        {
          data: "LinkedIn",
          className: "align-middle",
          orderable: false,
          render: function (data, type, row, meta) {
            var url = new URL(`../assets/linkedin.png`, import.meta.url).href;
            return (
              '<a href="' +
              data +
              '" target="_blank" style="background-color:rgba(10, 102, 194, 0.1); padding: 9px; border-radius: 8px; display: inline-block;"><span class="d-flex align-items-center"> <img src="' +
              url +
              '" style="height:20px; width:20px;" /><span style="display:inline-block"></span></span></a>'
            );
          },
        },

        // {
        //   data: "pageViews",
        //   defaultContent: "-",
        //   orderData: [9],
        //   targets: [8],
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //       return data.toLocaleString(undefined, {
        //         maximumFractionDigits: 1,
        //       });
        //     }
        //   },
        // },
        // {
        //   data: "pageViews",
        //   visible: false,
        //   searchable: false,
        //   targets: [9],
        // },
        // {
        //   data: "viewed",
        //   defaultContent: "-",
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //     }
        //   },
        // },
        // {
        //   data: "lastSeen",
        //   className: "text-center align-middle",
        //   type: "date",
        // },
      ],
      columns2: [
        {
          data: "company",
          defaultContent: "",
          orderData: [1],
          targets: [0],
          className: "align-middle",
          render: function (data, type, row, meta) {
            var url;
            if (props.propertyId == "6529ae4a668aae205367dd72") {
              url = new URL(`../assets/${row.logo}`, import.meta.url).href;
            } else {
              // url = row.logo;
              url = new URL(`../assets/${row.logo}`, import.meta.url).href;
            }
            var style =
              "height:25px; width:25px; margin: 0 19px 0 0; border-radius: 50%;";
            return (
              '<span class="d-flex align-items-center"><img src="' +
              url +
              '" style="' +
              style +
              '" /><span style="display:inline-block">' +
              data +
              "</span></span>"
            );
          },
        },
        {
          data: "company",
          visible: false,
          searchable: false,
          targets: [1],
        },
        {
          data: "location",
          className: "text-center align-middle",
        },
        {
          data: "website",
          className: "align-middle",
          orderable: false,
          render: function (data, type, row, meta) {
            var url = new URL(`../assets/world-wide-web.png`, import.meta.url)
              .href;
            return (
              '<a href="' +
              data +
              '" target="_blank" style="background-color:rgba(10, 102, 194, 0.1); padding: 9px; border-radius: 8px; display: inline-block;"><span class="d-flex align-items-center"> <img src="' +
              url +
              '" style="height:20px; width:20px;" /></span></a>'
            );
          },
        },
        {
          data: "industry",
          className: "text-center align-middle",
        },
        {
          data: "size",
          className: "text-center align-middle",
        },
        // {
        //   data: "pageViews",
        //   defaultContent: "-",
        //   orderData: [7],
        //   targets: [6],
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //       return data.toLocaleString(undefined, {
        //         maximumFractionDigits: 1,
        //       });
        //     }
        //   },
        // },
        // {
        //   data: "pageViews",
        //   visible: false,
        //   searchable: false,
        //   targets: [7],
        // },
        // {
        //   data: "viewed",
        //   defaultContent: "-",
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //     }
        //   },
        // },
        // {
        //   data: "lastSeen",
        //   className: "text-center align-middle",
        //   type: "date",
        // },
      ],
      allContacts: contacts_data,
      allCompanies: companies_data,
      contacts_identified: ref(null),
      companies_identified: ref(null),
      isContactsLoading: ref(false),
      isCompaniesLoading: ref(false),
    };
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    },
  },
  components: {
    PropertyDashboardSidebarNav,
    DatatableComponent,
  },
  computed: {
    startDate() {
      return moment(this.dateRange[0]).format("YYYY-MM-DD");
    },
    endDate() {
      var endDate = this.dateRange[1];
      if (!endDate) endDate = this.dateRange[0];
      return moment(endDate).format("YYYY-MM-DD");
    },
  },
  methods: {
    updateRange(type) {
      var yesterday = this.yesterday;
      this.rangeType = type;
      switch (type) {
        case "yesterday":
          this.dateRange = [yesterday, yesterday];
          break;
        case "last7":
          this.dateRange = [
            moment(yesterday).subtract(6, "d").toDate(),
            yesterday,
          ];
          break;
        case "last30":
          this.dateRange = [
            moment(yesterday).subtract(29, "d").toDate(),
            yesterday,
          ];
          break;
        case "thisMonth":
          this.dateRange = [
            moment(yesterday).startOf("month").toDate(),
            yesterday,
          ];
          break;
        case "lastMonth":
          this.dateRange = [
            moment(yesterday).subtract(1, "months").startOf("month"),
            moment(yesterday).subtract(1, "months").endOf("month"),
          ];
          break;
        case "all":
          var start = this.startDate
            ? moment(this.startDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : moment("2022-10-01").utc().format("YYYY-MM-DD HH:mm:ss");
          var endDate = this.endDate
            ? moment(this.endDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : yesterday;
          this.dateRange = [start, endDate];
          break;
      }
    },
    getContacts(startDate, endDate) {
      var _this = this;
      _this.isContactsLoading = true;
      var info = {
        startDate: startDate,
        endDate: endDate,
        propertyId: _this.propertyId,
      };
      api()
        .getMAContacts(info)
        .then((res) => {
          if (res.data) {
            _this.contacts_identified = res.data;
            _this.isContactsLoading = false;
          }
        });
    },
    getCompanies(startDate, endDate) {
      var _this = this;
      _this.isCompaniesLoading = true;
      var info = {
        startDate: startDate,
        endDate: endDate,
        propertyId: _this.propertyId,
      };
      api()
        .getMACompanies(info)
        .then((res) => {
          if (res.data) {
            _this.companies_identified = res.data;
            _this.isCompaniesLoading = false;
          }
        });
    },
    getData() {
      if (this.propertyId == "6529ae4a668aae205367dd72") {
        this.contacts_identified = this.allContacts;
        this.companies_identified = this.allCompanies;
      } else {
        this.getContacts(this.startDate, this.endDate);
        this.getCompanies(this.startDate, this.endDate);
      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    projectId(newValue) {
      console.log(newValue);
      if (newValue > 1) {
        this.contacts_identified = this.allContacts.slice(
          (newValue - 2) * 5,
          (newValue - 1) * 5
        );
        this.companies_identified = this.allCompanies.slice(
          (newValue - 2) * 5,
          (newValue - 1) * 5
        );
      } else {
        this.contacts_identified = this.allContacts;
        this.companies_identified = this.allCompanies;
      }
      this.kpis = this.kpisArr[newValue - 1];
    },
    dateRange(newVal, oldVal) {
      if (oldVal.length > 0) {
        // this.clear();
        this.getData();
      }
    },
  },
};
</script>
